import React from "react"
import tw from "twin.macro"
import logo from "../images/logo.png"
import instagram from "../images/instagram.svg"
import facebook from "../images/facebook.svg"
import twitter from "../images/twitter.svg"

const CenteredDivA = tw.div`flex h-screen p-10`
const CenteredDivB = tw.div`m-auto flex flex-col`

const ImgDiv = tw.div`self-center py-8`
const InfoDiv = tw.div`text-2xl`
const EmailDiv = tw.div`text-center py-4`
const LinkEmail = tw.a`text-3xl text-blue-500`
const SocialDiv = tw.div`self-center flex flex-row py-4`
const SocialIcon = tw.a`px-2`

export default () => (
  <CenteredDivA>
    <CenteredDivB>
      <ImgDiv>
        <img alt="logo" src={logo} />
      </ImgDiv>
      <InfoDiv>
        Hemos dejado de dar servicio, para cualquier duda puedes contactar con
        nosotros en:
      </InfoDiv>
      <EmailDiv>
        <LinkEmail href="mailto:hola@olemiscojines.com">
          hola@olemiscojines.com
        </LinkEmail>
      </EmailDiv>
      <SocialDiv>
        <SocialIcon href="https://www.instagram.com/olemiscojines/">
          <img src={instagram} height="50" width="50" alt="Instagram Logo" />
        </SocialIcon>
        <SocialIcon href="https://www.twitter.com/olemiscojines">
          <img src={twitter} height="50" width="50" alt="Twitter Logo" />
        </SocialIcon>
        <SocialIcon href="https://www.facebook.com/olemiscojines">
          <img src={facebook} height="50" width="50" alt="Facebook Logo" />
        </SocialIcon>
      </SocialDiv>
    </CenteredDivB>
  </CenteredDivA>
)
